@import 'borders';
@import 'bp';
@import 'fonts';
@import 'palette';
@import 'spacing';

$login-button-min-height: 36px;

.button-container a {
    font-size: $fs--1;
    min-height: $login-button-min-height;
    text-transform: uppercase;
}

.button-container a,
.button-container a.padded {
    padding: $spacing-0 $spacing-2;
}

.flex-group-1,
.flex-group-3 {
    flex-basis: 40%;
}

.flex-group-1,
.flex-group-2,
.flex-group-3 {
    align-content: center;

    > * {
        display: inherit;
    }

    > a {
        padding: 0;
    }
}

.flex-group-1 {
    justify-content: flex-start;

    > * + * {
        margin-left: $spacing-2;
    }
    // TODO replace in MR: ECORD-2552
    > a:first-child {
        @media screen and (min-width: $bp-hamburger-hide) {
            visibility: hidden;
        }
    }
}

.flex-group-2 {
    flex-basis: 20%;
    justify-content: center;
}

.flex-group-3 {
    justify-content: flex-end;

    > * + * {
        margin-left: $spacing-4;
    }
}

.label {
    font-size: $fs-0;
    margin-left: $spacing--1;
    text-transform: none;
}
