@import 'fonts';
@import 'palette';
@import 'spacing';

/* Headline - H1 */
@mixin font1 {
    color: $dark-color;
    font-family: $ff-brandon-grotesque-web-black;
    font-size: $fs-07;
    line-height: $spacing-6;
    text-transform: uppercase;
}

/* Bold Footer */
@mixin font2 {
    color: $dark-color;
    font-family: $ff-interstate-bold;
    font-size: $fs--1;
    line-height: $spacing-2;
}

/* Tiny Legal */
@mixin font3 {
    color: $neutral-color-16;
    font-family: $ff-interstate-light;
    font-size: $fs--2;
    line-height: $spacing-02;
}

/* Light Breadcrumbs */
@mixin font4 {
    color: $neutral-color-6;
    font-family: $ff-interstate-light;
    font-size: $fs--1;
    line-height: 1;
}

/* Bright Link */
@mixin font5 {
    color: $brand-color-light-1;
    font-family: $ff-interstate-bold;
    font-size: $fs--1;
    line-height: $spacing-01;
    text-decoration-line: underline;
}

/* DaaS/Title/3 - Product Name */
@mixin font6 {
    color: $dark-color;
    font-family: $ff-interstate-bold;
    font-size: $fs-3;
    line-height: $lh-2;
}

/* DaaS/Title/5 - Item Inside Combo */
@mixin font7 {
    color: $dark-color;
    font-family: $ff-interstate-bold;
    font-size: $fs-0;
    line-height: $spacing-1;
}

/* DaaS/Label/(bold) - Product Modification */
@mixin font8 {
    color: $neutral-color-16;
    font-family: $ff-interstate-bold;
    font-size: $fs--1;
    line-height: $spacing-1;
    text-transform: uppercase;
}

/* DaaS/Body Text/Small - Product Modification bullet */
@mixin font9 {
    color: $neutral-color-16;
    font-family: $ff-interstate-light;
    font-size: $fs--1;
    line-height: $spacing-1;
}

/* DaaS/Body Text/Large - Currency $$$ */
@mixin font10 {
    color: $neutral-color-16;
    font-family: $ff-interstate-light;
    font-size: $fs-2;
    line-height: $spacing-3;
}

/* DaaS/Title/4 - Input # */
@mixin font11 {
    color: $dark-color;
    font-family: $ff-interstate-bold;
    font-size: $fs-1;
    line-height: $spacing-2;
}

/* DaaS/Body Text/Medium */
@mixin font12 {
    color: $neutral-color-16;
    font-family: $ff-interstate-light;
    font-size: $fs-0;
    line-height: $fs-2;
}

/* CTA */
@mixin font13 {
    color: $light-color;
    font-family: $ff-interstate-bold;
    font-size: $fs-2;
    line-height: $spacing-2;
    text-transform: uppercase;
}
