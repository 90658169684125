@import 'borders';
@import 'fonts';
@import 'palette';
@import 'spacing';

$user-icon-size: 24px;
$chevron-icon-size: 18px;

.absolute {
    position: absolute;
}

.login-toolbar {
    display: flex;
    flex-wrap: wrap;
    min-height: $spacing-7;
    position: relative;
    text-align: center;

    @media(min-width: 640px) {
        border-bottom: none;
        margin: auto;
    }
}

.login-user {
    border-bottom: $bw-0 solid $neutral-color-1;
    margin: 0 $spacing-4;
    width: 100%;

    @media(min-width: 640px) {
        visibility: hidden;
    }
}

.login-user-open {
    border-bottom: none;
}

.login {
    color: $dark-color;
    display: inline-block;
    font-family: $ff-brandon-grotesque-web-black;
    font-size: $fs-1;
    margin: 0 auto;
    padding: $spacing-1;
    text-transform: uppercase;
}

.label {
    font-size: $fs-0;
    margin-left: $spacing--1;
    text-transform: none;
}

.condensed {
    max-width: $spacing-10;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.icon-user {
    height: $user-icon-size;
    margin-left: $spacing-02;
    width: $user-icon-size;
}

.icon-chevron {
    height: $chevron-icon-size;
    width: $chevron-icon-size;
}

.icon-rotate {
    rotate: 90deg;
}

.button {
    align-items: center;
    background-color: transparent;
    border: none;
    color: $neutral-color-6;
    display: flex;
    height: 56px;
    padding: 0;
}

.mobile-hidden {
    display: none;

    @media(min-width: 640px) {
        display: flex;
    }
}

.desktop-hidden {
    display: flex;

    @media(min-width: 640px) {
        display: none;
    }
}

@media screen and (min-width: 640px) {
    .login-toolbar {
        justify-content: center;
        min-height: $spacing-10;
    }

    .logo-ta-co {
        align-items: initial;
        height: $spacing-10;
        width: $spacing-10;
    }
}
