@import 'borders';
@import 'bp';
@import 'fonts';
@import 'palette';
@import 'spacing';

$active-offset: 18px;
$dropdown-container: 310px;
$icon-size: 24px;
$icon-underline-size: 28px;
$logo-size: 32px;

@mixin underline {
    height: $bw-2;
}

.button-container a {
    font-size: $fs--1;
    padding: $spacing-00 $spacing-0;
    text-transform: uppercase;

    @media (min-width: $bp-lg) {
        font-size: $fs-0;
        padding: $spacing-00 $spacing-2;
    }
}

.cart-container {
    position: relative;

    &.disable {
        pointer-events: none;
    }
}

.cart-total {
    margin-left: $spacing--1;
}

.condensed {
    max-width: $spacing-10;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.divider {
    color: $neutral-color-8;
    margin-right: $spacing-2;
}

.dropdown-container {
    background-color: $light-color;
    box-shadow: -$spacing--1 $spacing--1 $spacing-01 $spacing--3
        rgba($brand-color, 0.1);
    display: none;
    margin-right: 0;
    min-width: $dropdown-container;
    position: absolute;
    right: 0;
    top: $spacing-08;
}

.dropdown-element {
    border-bottom: $bw-0 solid $neutral-color;
    padding: $spacing-3 0 $spacing-3 $spacing-08;
    text-align: left;

    &:nth-of-type(4) {
        border-bottom: 0;
    }

    &:last-of-type {
        padding: $spacing-03 0 $spacing-3 $spacing-08;
    }

    .dropdown-button {
        max-width: $spacing-19;

        &:hover {
            color: $light-color;
        }
    }

    .dropdown-link {
        color: $dark-color-2;

        &:hover {
            color: $brand-color;
        }
    }

    .dropdown-logout {
        color: $brand-color-light-1;
    }
}

.dropdown-list {
    width: 100%;
}

.dropdown-show {
    display: block;
    z-index: 100;
}

.icon {
    height: $icon-size;
    width: $icon-size;
}

.icon-animate {
    position: relative;

    @media (min-width: $bp-sm) {
        &:hover {
            &::after {
                @include underline;
            }
        }
    }

    &::after {
        background-color: $brand-color;
        bottom: -$active-offset;
        color: $brand-color;
        content: '';
        height: 0;
        left: -$spacing--3;
        opacity: 1;
        position: absolute;
        transition: 200ms all ease;
        width: $icon-underline-size;
    }
}

.icon-animate.animate {
    &::after {
        @include underline;
    }
}

.indicator {
    position: absolute;
    right: -$spacing-0;
    top: -$spacing-0;
}

.label {
    margin-left: $spacing--1;
    text-transform: none;
}

.menu {
    height: $spacing-02;
    width: $spacing-2;
}

.tblogo {
    height: $spacing-4;
    width: $logo-size;
}

.tooltip {
    left: 50%;
    opacity: 0;
    transform: translate(-50%, $spacing--1);
    position: absolute;
    top: 100%;
    transition-duration: 350ms;
    transition-property: opacity, transform, visibility;
    transition-timing-function: ease-in;
    visibility: hidden;
    z-index: 100;

    &.active {
        opacity: 1;
        transform: translate(-50%, 0);
        visibility: visible;
    }

    &.hideOnDesktop {
        @media (min-width: $bp-sm) {
            display: none;
        }
    }
}

.user-button {
    align-items: center;
    background-color: transparent;
    border: none;
    color: $neutral-color-6;
    display: flex;
    font-family: $ff-interstate-regular;
    font-size: $fs-0;
    padding: 0;

    &:hover {
        color: $brand-color;
        cursor: pointer;
    }
}
